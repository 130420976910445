import React from "react";

const experienceContent = [
  // {
  //   year: "2021 - 2022",
  //   position: "Part Time Web Developer",
  //   compnayName: "Molto Digital Limited",
  //   details: ` Built and maintained 30+ responsive websites for clients in a variety of industries`,
  // },
  // {
  //   year: "2016 - NOW",
  //   position: " Web Developer",
  //   compnayName: "Freelance",
  //   details: `Designed and built 10+ e-commerce, CMS, and responsive websites`,
  // },
  // {
  //   year: "2021 - 2022",
  //   position: "Part Time Web Developer",
  //   compnayName: "Molto Digital Limited",
  //   details: ` Built and maintained 30+ responsive websites for clients in a variety of industries`,
  // },
  {
    year: "2015 - NOW",
    position: " Web Developer",
    compnayName: "Freelance",
    details: ` Built and maintained 30+ responsive websites for clients in a variety of industries`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
