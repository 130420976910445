import React from "react";

const Address = () => {
  return (
    <>
   
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">mail me</span>{" "}
        <a href="mailto:hello@andersonch.com">hello@andersonch.com</a>
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-phone-square position-absolute"></i>
        <span className="d-block">call me</span>{" "}
        <a href="Tel: +852 91750707">+852 91750707</a>
      </p>
      {/* End .custom-span-contact */}
    </>
  );
};

export default Address;
